import React, { useEffect, useState } from "react";
import {
  pinDoc,
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setActivePage,
  setPinnedSearches,
  setToaster,
  setTotalDoc,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { savefilteroplength } from "../../redux/actions";
import { connect } from "react-redux";
import { Pagination } from "semantic-ui-react";
import { searchApi, addPinDocument, deletePinDocument } from "../apiCall";
import KeycloakUserService from "../../keycloak/KeycloakUserService.js";
import XauthResultDisplay from "./XauthResultDisplay";
import { Loader } from "semantic-ui-react";
import IngResultDisplay from "./IngResultDisplay";

const SearchResultBox = (props) => {
  const perPage = 10;
  const results = 10;
  let JSONParse = props.searchResult && JSON.parse(props.searchResult);
  let JSONParseAuthDocs =
    props.searchResultAuthDocs && JSON.parse(props.searchResultAuthDocs);
  const [searchResult, setSearchResult] = useState(
    JSONParse ? JSONParse : null
  );
  const [searchResultAuthDocs, setSearchResultAuthDocs] = useState(
    JSONParseAuthDocs ? JSONParseAuthDocs : null
  );
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);
  const [pinnedDocs, setPinnedDocs] = useState(props.pinnedSearches);
  const [loading, setLoading] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const navigate = useNavigate();

  //function for adding a pindoc
  const pinTheDoc = (result) => {
    setApiCallInProgress(true);
    let pindoc = {
      documentId: result.id,
      searchResults: result,
    };

    addPinDocument(pindoc)
      .then((data) => {
        setPinnedDocs((allReadyPinnedDoc) => [
          ...allReadyPinnedDoc,
          data.data.data.pindocumentId,
        ]);
        data.data.data.pindocumentId.searchResults = result
        console.log("data",data.data.data.pindocumentId);
        props.setPinnedSearches(
          [...pinnedDocs, data.data.data.pindocumentId].reverse()
        );
        setApiCallInProgress(false);
      })
      .catch(() => {
        props.setToaster(true);
      });
  };

  //function for unpinning document, delete from database the document is pinned
  const unpindoc = (pinId) => {
    setApiCallInProgress(true);
    deletePinDocument(pinId)
      .then(() => {
        setPinnedDocs((result) =>
          result.filter((item) => item.pinId !== pinId)
        );
        props.setPinnedSearches(
          pinnedDocs.filter((item) => item.pinId !== pinId).reverse()
        );
        setApiCallInProgress(false);
      })
      .catch(() => {
        props.setToaster(true);
      });
  };

  let getFirstTwoSentence = (data) => {
    let sentences = data.split(". ");
    let returnSummary = "";
    do {
      returnSummary += sentences[0] += ". ";
      sentences.shift();
    } while (returnSummary.length < 500 && sentences.length);
    // let stringWithoutSubstrings = returnSummary.replace(/&[^;]+;/g, '');
    if (returnSummary.length > 500) {
      let words = returnSummary.split(/\s+/);
      returnSummary = words.slice(0, 70).join(" ");
      returnSummary += "...";
    }

    return returnSummary;
  };

  useEffect(() => {
    let JSONParseafter = JSON.parse(props?.searchResult);
    setSearchResult(JSONParseafter);
    if (
      props &&
      props.searchResultAuthDocs &&
      props.searchResultAuthDocs.length
    ) {
      let authJSONParseafter = JSON.parse(props?.searchResultAuthDocs);
      setSearchResultAuthDocs(authJSONParseafter);
    } else {
      setSearchResultAuthDocs(null);
    }
  }, [props?.searchResult, props?.searchResultAuthDocs]);

  // useeffect runs when the filter data props get changed
  useEffect(() => {
    if (Object.keys(JSON.parse(props.newFilter)).length > 0) {
      getDocs(props.activePage * results - results);
    }
  }, [props.newFilter]);

  function handleSessionOut() {
    if (KeycloakUserService.isTokenExpired()) {
      navigate("/");
      props.saveSearchWord("");
      localStorage.clear();
      window.location.reload(false);
      KeycloakUserService.doLogout();
    }
  }
  //function for fetching all document with query
  const getDocs = async (start) => {
    handleSessionOut();
    setLoading(true);
    var queryToExecute;
    let filterData = [];

    let filteringdataprop = JSON.parse(props?.newFilter);
    if (filteringdataprop?.type && filteringdataprop?.type !== "") {
      filterData.push({
        field: "type",
        value: filteringdataprop.type,
      });
    }
    if (filteringdataprop?.equipment && filteringdataprop?.equipment !== "") {
      filterData.push({
        field: "equipment",
        value: filteringdataprop.equipment,
      });
    }
    if (props.searchedWord !== "" && props.searchedWord !== undefined) {
      queryToExecute = {
        q: props.searchedWord,
        rows: 10,
        start: start !== undefined ? start : 0,
        filter: filterData,
      };

      await searchApi(queryToExecute)
        .then((res) => {
          props.saveSearchResult(JSON.stringify(res.data.response.docs));
          res.data.response?.authDocs?.length > 0
            ? props.saveSearchResultAuthDocs(
                JSON.stringify(res.data.response.authDocs)
              )
            : props.saveSearchResultAuthDocs("");
          setLoading(false);
          props.setTotalDoc(res.data.response.numFound);

          navigate("/searchResult");
        })
        .catch((error) => {
          //toaster is disabled since toaster is shown uneneceesary while applying filter
          //props.setToaster(true);
          setLoading(false);
          if (
            error.response?.data === "Access denied" ||
            // eslint-disable-next-line no-magic-numbers
            error.response?.status === 403
          ) {
            //KeycloakUserService.doLogout();
          }
        });
    }
  };

  function pageChange(e, data) {
    props.setActivePage(data.activePage);

    // handleSessionOut();
    window.scrollTo(0, 0);
    //props.dispatch(updatePage(data.activePage));
    getDocs(data.activePage * results - results);
  }

  return (
    <>
      {loading ? (
        <div
          className={`border shadow-md rounded-md ml-14 mr-14 mt-5 w-none ${
            featureFlag["Q&A engine"] ? "md:w-[58.34em]" : "md:w-[83.34em]"
          }`}
        >
          <div className="loader">
            <Loader active inline />
          </div>
        </div>
      ) : (
        <div>
          <div>
            {props.totalDoc === 0 && (
              <div
                className="border shadow-md rounded-md ml-14 mr-14 mt-6  w-none
                md:w-[82.34em]"
              >
                No Result found
              </div>
            )}
            {/* displaying documents created from the voauther if fetched in search */}
            <div>
              {searchResultAuthDocs?.map((result) => {
                return (
                  <XauthResultDisplay
                    result={result}
                    pinTheDoc={pinTheDoc}
                    unpindoc={unpindoc}
                    getFirstTwoSentence={getFirstTwoSentence}
                    pinnedDocs={pinnedDocs}
                    apiCallInProgress={apiCallInProgress}
                  />
                );
              })}
            </div>
            {/* displaying other docs in search result getting from solr */}
            <div>
              {searchResult?.map((result) => {
                return (
                  <IngResultDisplay
                    result={result}
                    pinTheDoc={pinTheDoc}
                    unpindoc={unpindoc}
                    getFirstTwoSentence={getFirstTwoSentence}
                    pinnedDocs={pinnedDocs}
                    apiCallInProgress={apiCallInProgress}
                  />
                );
              })}
            </div>
          </div>
          {/* pagination part */}
          {JSON.parse(props.searchResult).length > 0 && (
            <div className="pagination-container">
              <Pagination
                activePage={props.activePage}
                onPageChange={(e, data) => pageChange(e, data)}
                totalPages={Math.ceil(JSON.parse(props.totalDoc) / perPage)}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    knowledgeSrcFilter: state.knowledgeSrcFilter,
    relatedEquipmentFilter: state.relatedEquipmentFilter,
    searchedWord: state.searchedWord,
    searchResult: state.searchResult,
    searchResultAuthDocs: state.searchResultAuthDocs,
    activePage: state.activePage,
    totalDoc: state.totalDoc,
    pinnedSearches: state.pinnedSearches,
    newFilter: state.newFilter,
    feedbackList: state.feedbackList,
    featureFlag: state.featureFlag,
  };
};

export default connect(mapStateToProps, {
  pinDoc,
  savefilteroplength,
  saveSearchResult,
  saveSearchWord,
  saveSearchResultAuthDocs,
  setActivePage,
  setToaster,
  setTotalDoc,
  setPinnedSearches,
})(SearchResultBox);
